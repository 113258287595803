import { ContentPaginatedInterface } from "./content.type";
import { SiteResponse } from "./site.type";
import { UserResponse } from "./user.type";

export enum DeliveryTime {
  MORNING = "MORNING",
  AFTERNOON = "AFTERNOON",
  NIGHT = "NIGHT",
}

export enum DeliveryState {
  SCHEDULED = "SCHEDULED",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
  IN_PROGRESS = "IN_PROGRESS",
  CANCELLED = "CANCELLED",
  DELIVERED = "DELIVERED",
  COMPLETED = "COMPLETED",
}

export interface DeliveryInterface {
  id: string;
  user: UserResponse;
  orderName: string;
  deliveryDate: string;
  deliveryTime: DeliveryTime;
  purchaseOrderNumber: string;
  purchaseOrderFile: string;
  comment?: string;
  email: string;
  createdAt: string;
  supplier?: UserResponse;
  site: SiteResponse;
  state: DeliveryState;
  delivered: boolean;
  deleted: boolean;
  active: boolean;
}

export type PaginatedDeliveryInterface =
  ContentPaginatedInterface<DeliveryInterface>;
