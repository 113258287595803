import { Fragment, useState, useReducer } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import InfoItem from "../UI/InfoItem/InfoItem";
import Modal from "../UI/Modal/Modal";
import {
  BIDDING_TYPES,
  labelType,
  calculateMinimumDecrement,
  formatDate,
  getTableHeaders,
  currencyFormatter,
} from "../../utils";
import { OfferResponse, OrderState } from "../../types";
import OrderFileList from "../OrderFileList/OrderFileList";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

const actions = {
  UPDATE_OFFER_PRICE: "UPDATE_OFFER_PRICE",
};

type OfferSummaryProps = {
  offer: OfferResponse;
  onUpdateOffer: (offer: any) => void;
};

const OfferSummary = ({ offer, onUpdateOffer }: OfferSummaryProps) => {
  const [open, setOpen] = useState(false);
  const [event, updateEvent] = useReducer(
    (state: any, action: any) => {
      const newState = { ...state };
      switch (action.type) {
        case actions.UPDATE_OFFER_PRICE: {
          const price = parseFloat(action.value);
          if (isNaN(price) || price === 0) {
            newState.offer = null;
            delete newState.errors[action.offerId];
            return newState;
          }

          if (price > parseFloat(action.minDecrement) || price < 0) {
            newState.errors[action.offerId] =
              `${i18next.t("validations.lowerThanX")} ${action.minDecrement}.`;
          } else {
            delete newState.errors[action.offerId];
            newState.offer = { offerId: action.offerId, price: price };
          }
          return newState;
        }
      }
    },
    { offer: null, errors: {} }
  );

  const thIsBid = getTableHeaders(
    offer.order.type,
    offer.order.currency,
    offer.order.measurementUnit,
    offer.order.expireDate
  );

  const onSubmit = () => {
    const body = {
      offerId: event?.offer?.offerId,
      price: event?.offer?.price,
    };
    onUpdateOffer(body);
  };

  const minDecrement = calculateMinimumDecrement(
    offer?.price,
    offer?.order?.minimumDecrement
  );

  const errorMessage = event.errors[offer?.id];

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("tenders.seller.offer")}
        message={i18next.t("tenders.seller.question")}
        onClickSuccess={onSubmit}
        onClickCancel={() => setOpen(false)}
      />
      <div className="mt-4">
        <div className="sm:flex sm:items-center">
          {offer && (
            <div className="sm:flex-auto">
              <InfoItem
                name={i18next.t("tenders.form.company")}
                description={
                  <span className="uppercase font-bold text-spectum">
                    {offer.order?.company?.name}
                  </span>
                }
              />
              <InfoItem
                name={i18next.t("tenders.form.fullName")}
                description={offer.order?.user?.fullName}
              />
              <InfoItem
                name={i18next.t("tenders.form.email")}
                description={offer.order?.user?.email}
              />
              <InfoItem
                name={i18next.t("tenders.form.phone")}
                description={offer.order?.user?.phone}
              />
              <InfoItem
                name={i18next.t("tenders.form.tenderType")}
                description={labelType(offer.order?.type)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.initialDate")}
                description={formatDate(offer.order?.startDate)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.endDate")}
                description={formatDate(offer.order?.finishDate)}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.paymentMethod")}
                description={offer.order?.paymentMethod}
              />
              <InfoItem
                name={i18next.t("tenders.tenderSummary.paymentTerm")}
                description={
                  <>
                    {offer.order?.paymentTerm}
                    {offer.order?.paymentTerm === 1
                      ? ` ${i18next.t("opportunities.table.day")}`
                      : ` ${i18next.t("opportunities.table.days")}`}
                  </>
                }
              />
              {offer.order?.comment && (
                <InfoItem
                  name={i18next.t("tenders.tenderSummary.comments")}
                  description={offer.order?.comment}
                />
              )}
              {offer.order?.files?.length > 0 && (
                <OrderFileList order={offer.order} />
              )}
            </div>
          )}
        </div>
        <div className="-mx-4 flex flex-col sm:-mx-6 md:mx-0">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                {thIsBid.map((t) => (
                  <th
                    key={t.id}
                    scope="col"
                    className="uppercase py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  >
                    {t.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-gray-200">
                <td className="py-5 text-xs">
                  <div className="font-medium text-gray-900 ">
                    {offer?.order?.material?.globalMaterialName}
                  </div>
                </td>
                <td className="py-5 text-xs text-gray-500">
                  {offer?.order.startSupplyDate ===
                  offer?.order.endSupplyDate ? (
                    formatDate(offer?.order.startSupplyDate)
                  ) : (
                    <>
                      {formatDate(offer?.order.startSupplyDate)} -{" "}
                      {formatDate(offer?.order.endSupplyDate)}
                    </>
                  )}
                </td>
                <td className="py-5 text-xs text-gray-500">
                  {offer?.order.quantity} {offer?.order.measurementUnit}
                </td>

                {offer?.order.type === BIDDING_TYPES.INVERSE_JOINT && (
                  <Fragment>
                    <td className="py-5 text-xs text-gray-900">
                      {offer?.order.initialPrice} {offer?.order.currency}
                    </td>
                    <td className="py-5 text-xs text-gray-500">
                      {offer?.order.minimumDecrement} %
                    </td>
                  </Fragment>
                )}
                <td className="py-5 text-xs text-gray-900 font-bold">
                  <div className="flex items-center gap-3 justify-between pr-4 relative">
                    <span className="w-full">
                      $ {offer?.price} {offer?.order.currency} /{" "}
                      {offer?.order.measurementUnit}
                    </span>
                    {offer?.order.type === BIDDING_TYPES.INVERSE &&
                      offer.order?.state === OrderState.OPEN && (
                        <>
                          <div className="flex gap-2 items-center relative w-full">
                            <input
                              type="number"
                              className={`mt-1 shadow-sm sm:text-xs rounded ${
                                errorMessage
                                  ? "border-red-500"
                                  : "border-gray-300"
                              }`}
                              max={calculateMinimumDecrement(
                                offer?.price,
                                offer?.order?.minimumDecrement
                              )}
                              placeholder={calculateMinimumDecrement(
                                offer?.price,
                                offer?.order?.minimumDecrement
                              )}
                              onChange={(e) =>
                                updateEvent({
                                  type: actions.UPDATE_OFFER_PRICE,
                                  value: e.target.value,
                                  offerId: offer?.id,
                                  minDecrement,
                                })
                              }
                            />
                            <div className="flex">
                              <span>{offer?.order.currency}</span> /
                              <span>{offer?.order.measurementUnit}</span>
                            </div>
                          </div>
                          {errorMessage && (
                            <span className="absolute right-0 text-xs text-red-600 -bottom-5">
                              {errorMessage}
                            </span>
                          )}
                        </>
                      )}
                  </div>
                </td>
                <td className="py-5 text-xs text-gray-900 font-bold">
                  {currencyFormatter(
                    offer?.order.quantity * offer.price,
                    offer?.order.currency
                  )}
                </td>
                <td className="py-5 text-xs text-gray-500">
                  {formatDate(offer?.expireDate)}
                </td>
                <td className="py-5 text-xs text-gray-500">
                  {offer?.comment ? offer?.comment : " - "}
                </td>
              </tr>
            </tbody>
          </table>
          {offer?.fileURL && (
            <div className="w-full mt-2 flex justify-end">
              <a
                href={offer?.fileURL}
                className="px-4 border border-transparent shadow-sm text-xxs font-medium rounded text-white bg-spectum-dark hover:bg-spectum-light focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase py-2 max-w-fit flex items-center gap-3"
              >
                <ArrowDownTrayIcon className="h-4 w-4" />
                {i18next.t("cta.downloadFile")}
              </a>
            </div>
          )}
          {offer?.order.type === BIDDING_TYPES.INVERSE &&
            offer.order?.state === OrderState.OPEN && (
              <div className="px-2 py-3 bg-gray-50 text-right">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setOpen(true);
                  }}
                  disabled={
                    Object.keys(event.errors).length > 0 || !event.offer
                  }
                  className={`${
                    Object.keys(event.errors).length || !event.offer
                      ? "bg-gray-200 text-gray-600 cursor-not-allowed"
                      : "bg-spectum hover:bg-spectum-light text-white"
                  } inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded focus:outline-none focus:ring-2 focus:ring-offset-2 uppercase`}
                >
                  {i18next.t("offer.offerNow")}
                </button>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default OfferSummary;
