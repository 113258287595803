import { Fragment, useState, useEffect, useReducer } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import i18next from "../../i18n";
import {
  mapStateToProps,
  mapDispatchToProps,
  BIDDING_TYPES,
  BIDDING_TYPES_LABELS,
  PN_TYPE,
  cutIdOnFirstHyphen,
} from "../../utils";
import { Steps, Loader, Modal } from "../../components";
import BiddingTypeSelector from "./BiddingTypeSelector/BiddingTypeSelector";
import SuppliersCheckboxList from "./SuppliersCheckboxList/SuppliersCheckboxList";
import PriceDecrementPayment from "./PriceDecrementPayment/PriceDecrementPayment";
import MultipleMaterialsBiddingForm from "./MultipleMaterialsBiddingForm/MultipleMaterialsBiddingForm";
import MultipleBiddingSummary from "./MultipleBiddingSummary/MultipleBiddingSummary";
import { useFileInputUploader } from "../../hooks";
import JointSuppliersCheckboxList from "./JointSuppliersCheckboxList/JointSuppliersCheckboxList";
import {
  DELIVERY_TYPE,
  OrdersTuSumbitRequest,
  UserResponse,
} from "../../types";
import { useBiddingSuppliers } from "../../hooks/useBiddingSuppliers";
import {
  useMultiplePurchaseRequest,
  usePurchaseRequest,
  useUpdatePurchaseRequest,
} from "../../hooks/usePurchaseRequests";
import { useInvitedSuppliers } from "../../hooks/useSuppliers";

const actions = {
  UPDATE_INTERNAL_CODE: "UPDATE_INTERNAL_CODE",
  UPDATE_PAYMENT_METHOD: "UPDATE_PAYMENT_METHOD",
  UPDATE_PAYMENT_TERM: "UPDATE_PAYMENT_TERM",
  UPDATE_START_DATE: "UPDATE_START_DATE",
  UPDATE_FINISH_DATE: "UPDATE_FINISH_DATE",
  UPDATE_EXPIRE_DATE: "UPDATE_EXPIRE_DATE",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  UPDATE_NAME: "UPDATE_NAME",
  ADD_FILE: "ADD_FILE",
  REMOVE_FILE: "REMOVE_FILE",
};

export type File = {
  fileName: string;
  type: string;
  fileURL: string;
};

const types = [
  {
    id: 1,
    title: BIDDING_TYPES_LABELS.NORMAL,
    value: BIDDING_TYPES.NORMAL,
    description: i18next.t("tenders.normal"),
  },
  {
    id: 2,
    title: BIDDING_TYPES_LABELS.INVERSE,
    value: BIDDING_TYPES.INVERSE,
    description: i18next.t("tenders.inverse"),
  },
];

const initalState = {
  paymentMethod: "",
  paymentTerm: "",
  startDateValue: "",
  finishDate: "",
  expireDate: false,
  name: "",
  comment: "",
  files: [] as File[],
};

type OrderByRequestProps = {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
  onCleanUserInvites: any;
  onCreateOrderInvitation: any;
  orderInvitation: any;
};

const OrderByRequest = ({
  user,
  onOpenNotification,
  onCleanUserInvites,
  onCreateOrderInvitation,
  orderInvitation,
}: OrderByRequestProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [ordersToSubmit, setOrdersToSubmit] = useState<OrdersTuSumbitRequest[]>(
    []
  );
  const [biddingType, setBiddingType] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<any>(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState([
    { id: 1, name: i18next.t("tenders.biddingType"), status: "current" },
    { id: 2, name: i18next.t("tenders.selectSuppliers"), status: "upcoming" },
    { id: 3, name: i18next.t("tenders.generalData"), status: "upcoming" },
    { id: 4, name: i18next.t("tenders.summary"), status: "upcoming" },
  ]);
  const [activeStep, setActiveStep] = useState(1);
  const [disabled, setDisabled] = useState(true);
  const purchaseRequestId = searchParams.get("purchaseRequestId");
  const multiplePurchaseRequestId = searchParams.get(
    "multiplePurchaseRequestId"
  );

  const { data: purchaseRequest, isLoading: isLoadingPurchaseRequests } =
    usePurchaseRequest(purchaseRequestId || "");

  const {
    data: multiplePurchaseRequest,
    isLoading: isLoadingMultiplePurchaseRequests,
  } = useMultiplePurchaseRequest(
    "/purchase-request/user/multiple/order/id/",
    multiplePurchaseRequestId || ""
  );

  const orderInvitationHandler = (isMultiple: boolean, data: any) => {
    if (orderInvitation.length === 0) return;
    if (isMultiple) {
      onCreateOrderInvitation(orderInvitation[0].emailsList, data[0].id);
    } else {
      onCreateOrderInvitation(orderInvitation[0].emailsList, data.id);
    }
    onCleanUserInvites();
  };

  const { mutate: updatePurchaseRequest, isPending: isUpdatingOrder } =
    useUpdatePurchaseRequest(
      (data) => {
        const isMultiple = multiplePurchaseRequestId ? true : false;
        onOpenNotification(i18next.t("tenders.success"));
        orderInvitationHandler(isMultiple, data);
        return navigate("/orders");
      },
      () => onOpenNotification(i18next.t("newMaterialForm.error"))
    );

  const [generalBidData, setGeneralBidData] = useReducer(
    (state: any, action: any) => {
      let newEvent = { ...state };
      switch (action.type) {
        case actions.UPDATE_NAME:
          newEvent = {
            ...newEvent,
            name: action.name,
          };
          break;
        case actions.UPDATE_START_DATE:
          newEvent = {
            ...newEvent,
            startDateValue: action.startDateValue,
            endDateValue: "",
          };
          break;
        case actions.UPDATE_FINISH_DATE:
          newEvent = {
            ...newEvent,
            endDateValue: action.endDateValue,
          };
          break;
        case actions.UPDATE_EXPIRE_DATE:
          newEvent = {
            ...newEvent,
            expireDate: action.expireDate,
          };
          break;
        case actions.UPDATE_PAYMENT_METHOD:
          newEvent = {
            ...newEvent,
            paymentMethod: action.paymentMethod,
          };
          break;
        case actions.UPDATE_PAYMENT_TERM:
          newEvent = {
            ...newEvent,
            paymentTerm: action.paymentTerm,
          };
          break;
        case actions.UPDATE_COMMENT:
          newEvent = {
            ...newEvent,
            comment: action.comment,
          };
          break;
        case actions.ADD_FILE:
          newEvent = {
            ...newEvent,
            files: [...newEvent.files, ...action.files],
          };
          break;
        case actions.REMOVE_FILE:
          newEvent = {
            ...newEvent,
            files: newEvent.files.filter(
              (_: any, index: number) => index !== action.index
            ),
          };
          break;
      }
      return newEvent;
    },
    initalState
  );

  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();

  const { data: suppliers, mutate: getBiddingSuppliers } =
    useBiddingSuppliers();

  const { mutate: getInvitedSuppliers, data: invitedSuppliers } =
    useInvitedSuppliers();

  const { handleSubmit, reset } = useForm();

  useEffect(() => {
    if (purchaseRequest && purchaseRequestId && ordersToSubmit.length === 0) {
      const { order } = purchaseRequest;
      ordersToSubmit.push({
        orderId: order.id,
        id: order.material.id,
        ean: order.material.ean,
        name: order.name,
        coin: order.material.coin,
        incoTerm: order.material.incoTerm,
        measurementUnit: order.material.measurementUnit,
        globalMaterialName: order.material.globalMaterialName,
        deliveryPlace: order.material.deliveryPlace,
        fileUrl: order.material.fileURL,
        internalCode: cutIdOnFirstHyphen(purchaseRequestId),
        type: order.material.type,
        quantity: order.quantity,
        startSupplyDate: order.startSupplyDate,
        expireDate: order.expireDate,
        files: order.files,
        spot: order.spot ? DELIVERY_TYPE.SPOT : DELIVERY_TYPE.PERIOD,
        endSupplyDate: order.endSupplyDate,
        purpose: order.purpose,
        application: order.application,
        suppliers: [],
      });
      setGeneralBidData({
        type: actions.ADD_FILE,
        files: purchaseRequest.order?.files || [],
      });
      setGeneralBidData({
        type: actions.UPDATE_NAME,
        name: order.name,
      });
      setGeneralBidData({
        type: actions.UPDATE_EXPIRE_DATE,
        expireDate: order.expireDate,
      });
    }
  }, [purchaseRequest]);

  useEffect(() => {
    if (
      multiplePurchaseRequest &&
      multiplePurchaseRequestId &&
      ordersToSubmit.length === 0
    ) {
      const files = multiplePurchaseRequest?.orders?.find(
        (order: any) => order.files && order.files.length > 0
      )?.files;
      multiplePurchaseRequest.orders.forEach((order: any) => {
        ordersToSubmit.push({
          orderId: order.id,
          multipleOrderId: order.multipleOrder.id,
          id: order.material.id,
          ean: order.material.ean,
          name: order.name,
          coin: order.material.coin,
          incoTerm: order.material.incoTerm,
          internalCode: cutIdOnFirstHyphen(multiplePurchaseRequestId),
          measurementUnit: order.material.measurementUnit,
          globalMaterialName: order.material.globalMaterialName,
          deliveryPlace: order.material.deliveryPlace,
          fileUrl: order.material.fileURL,
          purpose: order.purpose,
          application: order.application,
          expireDate: order.expireDate,
          type: order.material.type,
          quantity: order.quantity,
          spot: order.spot ? DELIVERY_TYPE.SPOT : DELIVERY_TYPE.PERIOD,
          startSupplyDate: order.startSupplyDate,
          files,
          endSupplyDate: order.endSupplyDate,
          suppliers: [],
        });
      });
      setGeneralBidData({
        type: actions.ADD_FILE,
        files: files || [],
      });
      setGeneralBidData({
        type: actions.UPDATE_NAME,
        name: multiplePurchaseRequest?.multipleOrder?.name,
      });
    }
  }, [multiplePurchaseRequest]);

  const handleNext = (step: number) => {
    if (step === 1) {
      setActiveStep((prevState: any) => prevState + 1);
      const mat = ordersToSubmit?.map((mat: any) => mat.id);
      getBiddingSuppliers(mat);
      getInvitedSuppliers(mat);
      setDisabled(true);
    }
    if (activeStep === 2) setActiveStep((prevState: any) => prevState + 1);
    if (activeStep === 3) setActiveStep((prevState: any) => prevState + 1);
    if (activeStep === 4) setActiveStep((prevState: any) => prevState + 1);
    if (activeStep === 5) setOpen(true);
  };

  const handleSupplierSelection = (supplier: any, materialId: string) => {
    setIsAllSelected(false);
    const foundOrder = ordersToSubmit.find(
      (order: any) => order.id === materialId
    );
    const indexOfSupplier = foundOrder?.suppliers.findIndex(
      (sup: any) => supplier.seller.id === sup.id
    );
    if (indexOfSupplier === -1) {
      const ordIndex = ordersToSubmit.findIndex(
        (order: any) => order.id === materialId
      );
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any, index: number) => {
          if (index === ordIndex) {
            return {
              ...item,
              suppliers: [...item.suppliers, { id: supplier.seller.id }],
            };
          }
          return item;
        });
        return newState;
      });
    } else {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const ordIndex = ordersToSubmit.findIndex(
          (order: any) => order.id === materialId
        );
        const newState = prevState.map((item: any, index: number) => {
          if (index === ordIndex) {
            return {
              ...item,
              suppliers: item.suppliers.filter(
                (sup: any) => sup.id != supplier.seller.id
              ),
            };
          }
          return item;
        });
        return newState;
      });
    }
  };

  const handleJointSupplierSelection = (supplier: any) => {
    setIsAllSelected(false);
    setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) =>
      prevState.map((order: any) => {
        const supplierExists = order.suppliers.some(
          (sup: any) => sup.id === supplier.id
        );
        return {
          ...order,
          suppliers: supplierExists
            ? order.suppliers.filter((sup: any) => sup.id !== supplier.id)
            : [...order.suppliers, { id: supplier.id }],
        };
      })
    );
  };

  const handleOrdersToSubmit = (
    invite: { emailsList: string[]; materialId: string },
    isJoint?: any
  ) => {
    if (isJoint) {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any) => {
          const updatedSuppliers = item.suppliers.filter(
            (supplier: any) => supplier.emailsList !== invite.emailsList
          );

          const emailExists = updatedSuppliers.length !== item.suppliers.length;

          return {
            ...item,
            suppliers: emailExists
              ? updatedSuppliers
              : [
                  ...item.suppliers,
                  { emailsList: invite.emailsList, materialId: item.id },
                ],
          };
        });
        return newState;
      });
    } else {
      const ordIndex = ordersToSubmit.findIndex(
        (order: any) => order.id === invite.materialId
      );
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any, index: number) => {
          if (index === ordIndex) {
            const updatedSuppliers = item.suppliers.filter(
              (supplier: any) => supplier.emailsList !== invite.emailsList
            );

            const emailExists =
              updatedSuppliers.length !== item.suppliers.length;

            return {
              ...item,
              suppliers: emailExists
                ? updatedSuppliers
                : [...item.suppliers, invite],
            };
          }
          return item;
        });
        return newState;
      });
    }
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any) => {
          return {
            ...item,
            suppliers: [],
          };
        });
        return newState;
      });
    } else {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any, index: number) => {
          const sups = suppliers[index]?.map((sup: any) => {
            return { id: sup.seller.id };
          });
          return {
            ...item,
            suppliers: sups,
          };
        });
        return newState;
      });
    }
    setIsAllSelected((prevState: any) => !prevState);
  };

  const handleSelectJointAll = () => {
    const suppliersObject = Object.keys(suppliers).map((key) => {
      return suppliers[key];
    });

    function getUniqueSuppliers(data: any) {
      const uniqueSuppliers = new Map();

      data.forEach((group: any) => {
        group.forEach((entry: any) => {
          const supplier = entry.seller;
          if (supplier && supplier.id) {
            uniqueSuppliers.set(supplier.id, supplier);
          }
        });
      });

      return Array.from(uniqueSuppliers.values());
    }

    const suppliersToShowList = getUniqueSuppliers(suppliersObject);

    if (isAllSelected) {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any) => {
          return {
            ...item,
            suppliers: [],
          };
        });
        return newState;
      });
    } else {
      setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
        const newState = prevState.map((item: any) => {
          const sups = suppliersToShowList.map((sup: any) => {
            return { id: sup.id };
          });
          return {
            ...item,
            suppliers: sups,
          };
        });
        return newState;
      });
    }
    setIsAllSelected((prevState: any) => !prevState);
  };

  const handlePriceDecrementChanges = (
    e: React.ChangeEvent<HTMLInputElement>,
    material: any,
    key: any
  ) => {
    setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
      const newState = prevState.map((mat: any) => {
        if (mat.id === material.id) {
          return { ...mat, [key]: e.target.value };
        }
        return mat;
      });
      return newState;
    });
  };

  const handleSupplyPeriod = (
    e: React.ChangeEvent<HTMLInputElement>,
    matToBid: any,
    type: any
  ) => {
    setOrdersToSubmit((prevState: OrdersTuSumbitRequest[]) => {
      const newState = prevState.map((mat: any) => {
        if (
          mat.id === matToBid.id &&
          type === "start" &&
          mat.spot === DELIVERY_TYPE.SPOT
        ) {
          return {
            ...mat,
            startSupplyDate: e.target.value,
            endSupplyDate: e.target.value,
          };
        } else if (mat.id === matToBid.id && type === "start") {
          return {
            ...mat,
            startSupplyDate: e.target.value,
            endSupplyDate: "",
          };
        } else if (mat.id === matToBid.id && type === "end") {
          return { ...mat, endSupplyDate: e.target.value };
        }
        if (mat.id === matToBid.id && type === DELIVERY_TYPE.SPOT) {
          return {
            ...mat,
            spot: e.target.value,
            startSupplyDate: mat.startSupplyDate,
            endSupplyDate: mat.startSupplyDate,
          };
        }
        return mat;
      });
      return newState;
    });
  };

  const handleSpecification = (e: any) => {
    const file = e?.target?.files;
    if (file) {
      handleSpecificationUpload(file);
    }
  };

  const onSubmit = () => {
    const dataToSubmit = ordersToSubmit.map((mat: OrdersTuSumbitRequest) => {
      const filteredSuppliers = mat?.suppliers.filter((sup: any) => sup.id);
      return {
        id: mat.orderId,
        paymentMethod: generalBidData.paymentMethod,
        startDate: new Date(generalBidData.startDateValue).toISOString(),
        finishDate: new Date(generalBidData.endDateValue).toISOString(),
        quantity: mat.quantity ? parseFloat(String(mat.quantity)) : 1,
        material: { id: mat.id },
        user_id: user?.id,
        user: { id: user?.id },
        company: { id: user?.company?.id },
        multipleOrder: mat.multipleOrderId ? { id: mat.multipleOrderId } : null,
        email: user?.email,
        files: generalBidData.files,
        plicationUrl: "",
        deliveryPlace: mat?.deliveryPlace
          ? { id: mat?.deliveryPlace?.id }
          : null,
        fullname: user?.fullName,
        application: mat?.application,
        purpose: mat?.purpose,
        name: generalBidData.name ?? mat?.name,
        fileUrl: mat?.fileUrl,
        measurementUnit: mat?.measurementUnit,
        incoTerm: mat?.incoTerm,
        currency: mat?.coin,
        spot: mat?.spot === DELIVERY_TYPE.SPOT ? true : false,
        startSupplyDate:
          mat?.startSupplyDate && new Date(mat?.startSupplyDate).toISOString(),
        endSupplyDate:
          mat?.endSupplyDate && new Date(mat?.endSupplyDate).toISOString(),
        expireDate: generalBidData.expireDate,
        type: biddingType,
        internalCode: mat?.internalCode,
        comment: generalBidData.comment,
        suppliers: filteredSuppliers,
        initialPrice:
          selectedType.value === BIDDING_TYPES.INVERSE && mat.initialPrice
            ? parseFloat(mat.initialPrice)
            : null,
        minimumDecrement:
          selectedType.value === BIDDING_TYPES.INVERSE && mat.minimumDecrement
            ? parseFloat(mat.minimumDecrement)
            : null,
        paymentTerm: parseInt(generalBidData.paymentTerm),
      };
    });
    updatePurchaseRequest(dataToSubmit);
    setOpen(false);
    reset();
  };
  const onError = () => setOpen(false);

  const updateStepsStatus = () => {
    let objIndex = steps.findIndex((obj) => obj.id === activeStep);
    setSteps((prevState: any) => {
      const newState = prevState.map((step: any) => {
        if (step.id === objIndex + 1) {
          return { ...step, status: "current" };
        }
        if (step.id > objIndex + 1) {
          return { ...step, status: "upcoming" };
        }
        if (activeStep === 5) {
          return { ...step, status: "complete" };
        }
        return { ...step, status: "complete" };
      });
      return newState;
    });
    if (activeStep === 5) {
      setSteps((prevState: any) => {
        const newState = prevState.map((step: any) => ({
          ...step,
          status: "complete",
        }));
        return newState;
      });
    }
  };

  const handleSelectType = (type: any) => {
    const complexType =
      type?.value === BIDDING_TYPES.NORMAL && multiplePurchaseRequestId
        ? BIDDING_TYPES.JOINT
        : BIDDING_TYPES.INVERSE_JOINT;
    setSelectedType(type);
    multiplePurchaseRequestId
      ? setBiddingType(complexType)
      : setBiddingType(type.value);
  };

  useEffect(() => {
    updateStepsStatus();
  }, [activeStep]);

  const verifyThirdStep = () => {
    const initialPrice = ordersToSubmit?.every(
      (el: any) => el.initialPrice > 0
    );
    const quantity = ordersToSubmit?.every((el: any) => el.quantity > 0);
    const startSupplyDate = ordersToSubmit?.every(
      (el: any) => el.startSupplyDate
    );
    const endSupplyDate = ordersToSubmit?.every((el: any) => el.endSupplyDate);
    const purpose = ordersToSubmit
      ?.filter((el: any) => el.type === PN_TYPE.SERVICE)
      ?.every((el: any) => el.purpose);
    const isService =
      ordersToSubmit?.filter((el: any) => el.type === PN_TYPE.SERVICE)?.length >
      0;
    const minimumDecrement = ordersToSubmit?.every(
      (el: any) => el.minimumDecrement > 0
    );
    if (
      selectedType?.value === BIDDING_TYPES.NORMAL ||
      biddingType === BIDDING_TYPES.JOINT
    ) {
      if (isService) {
        purpose && startSupplyDate && endSupplyDate
          ? setDisabled(false)
          : setDisabled(true);
      } else {
        quantity && startSupplyDate && endSupplyDate
          ? setDisabled(false)
          : setDisabled(true);
      }
    }
    if (
      selectedType?.value === BIDDING_TYPES.INVERSE ||
      biddingType === BIDDING_TYPES.INVERSE_JOINT
    ) {
      if (isService) {
        quantity && initialPrice && minimumDecrement && purpose
          ? setDisabled(false)
          : setDisabled(true);
      } else {
        quantity &&
        initialPrice &&
        minimumDecrement &&
        startSupplyDate &&
        endSupplyDate
          ? setDisabled(false)
          : setDisabled(true);
      }
    }
  };

  useEffect(() => {
    const startSupplyDate = ordersToSubmit?.every(
      (el: any) => el.startSupplyDate
    );
    const endSupplyDate = ordersToSubmit?.every((el: any) => el.endSupplyDate);
    if (activeStep === 1)
      selectedType !== null ? setDisabled(false) : setDisabled(true);
    if (activeStep === 2) {
      ordersToSubmit?.every((el: any) => el.suppliers?.length > 0)
        ? setDisabled(false)
        : setDisabled(true);
    }
    if (activeStep === 3) verifyThirdStep();
    if (activeStep === 4)
      generalBidData.paymentMethod &&
      generalBidData.startDateValue &&
      generalBidData.endDateValue &&
      generalBidData.paymentTerm &&
      startSupplyDate &&
      endSupplyDate
        ? setDisabled(false)
        : setDisabled(true);
  });

  return (
    <div className="px-4 mt-6">
      <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate uppercase">
        {i18next.t("navigation.newTender")}
      </h2>
      {isLoadingPurchaseRequests ||
      isUpdatingOrder ||
      isLoadingMultiplePurchaseRequests ? (
        <div className="pt-8">
          <Loader />
        </div>
      ) : (
        <div className="mt-4 border p-4 rounded">
          <Steps steps={steps} />
          {activeStep === 1 && (
            <Fragment>
              <BiddingTypeSelector
                types={types}
                onChangeType={handleSelectType}
                typeValue={selectedType}
              />
            </Fragment>
          )}
          {activeStep === 2 && suppliers && purchaseRequestId && (
            <div>
              {suppliers.find((sup: any) => sup?.length > 0) && (
                <div className="relative flex items-start pt-4 pb-2 border-b">
                  <div className="flex items-center gap-2">
                    <input
                      id="selectAll"
                      aria-describedby="comments-description"
                      name="selectAll"
                      type="checkbox"
                      checked={isAllSelected}
                      className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                      onChange={handleSelectAll}
                    />
                    <label
                      htmlFor="selectAll"
                      className="font-bold text-xs text-spectum-dark uppercase"
                    >
                      {i18next.t("order.selectAll")}
                    </label>
                  </div>
                </div>
              )}
              <SuppliersCheckboxList
                suppliers={suppliers}
                invitedSuppliers={invitedSuppliers || []}
                handleSupplierSelection={handleSupplierSelection}
                ordersToSubmit={ordersToSubmit}
                orderInvitation={orderInvitation}
                handleOrdersToSubmit={handleOrdersToSubmit}
              />
            </div>
          )}
          {activeStep === 2 && suppliers && multiplePurchaseRequestId && (
            <div>
              {suppliers.find((sup: any) => sup?.length > 0) && (
                <div className="relative flex items-start pt-4 pb-2 border-b">
                  <div className="flex items-center gap-2">
                    <input
                      id="selectAll"
                      aria-describedby="comments-description"
                      name="selectAll"
                      type="checkbox"
                      checked={isAllSelected}
                      className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
                      onChange={handleSelectJointAll}
                    />
                    <label
                      htmlFor="selectAll"
                      className="font-bold text-xs text-spectum-dark uppercase"
                    >
                      {i18next.t("order.selectAll")}
                    </label>
                  </div>
                </div>
              )}
              <JointSuppliersCheckboxList
                suppliers={suppliers}
                invitedSuppliers={invitedSuppliers || []}
                handleSupplierSelection={handleJointSupplierSelection}
                ordersToSubmit={ordersToSubmit}
                orderInvitation={orderInvitation}
                handleOrdersToSubmit={handleOrdersToSubmit}
              />
            </div>
          )}
          {activeStep === 2 && !suppliers && (
            <div className="pt-8">
              <Loader />
            </div>
          )}
          {activeStep === 3 && (
            <PriceDecrementPayment
              type={
                multiplePurchaseRequestId &&
                selectedType?.value === BIDDING_TYPES.INVERSE
                  ? BIDDING_TYPES.INVERSE_JOINT
                  : multiplePurchaseRequestId &&
                      selectedType?.value === BIDDING_TYPES.NORMAL
                    ? BIDDING_TYPES.JOINT
                    : selectedType?.value
              }
              materialsToBid={ordersToSubmit}
              handlePriceDecrementChanges={handlePriceDecrementChanges}
              handleSupplyPeriod={handleSupplyPeriod}
            />
          )}
          {activeStep === 4 && (
            <MultipleMaterialsBiddingForm
              type={biddingType}
              actions={actions}
              isLoadingUpload={isLoadingUpload}
              generalBidData={generalBidData}
              setGeneralBidData={setGeneralBidData}
              handleSpecification={handleSpecification}
              uploadedFileUrl={uploadedSpecificationFileUrl}
            />
          )}
          {activeStep === 5 && (
            <MultipleBiddingSummary
              orderSummary={ordersToSubmit}
              generalBidData={generalBidData}
              type={selectedType.value}
            />
          )}
          <div className="flex justify-end items-center gap-2 mt-8">
            <button
              className={`uppercase flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-xs font-medium ${
                activeStep === 1 ? "text-gray-400" : "text-gray-900"
              } bg-white`}
              onClick={() => setActiveStep((prevState: any) => prevState - 1)}
              disabled={activeStep === 1}
            >
              {i18next.t("cta.back")}
            </button>
            <button
              className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-xs font-medium rounded ${
                disabled
                  ? "bg-white text-gray-400"
                  : "bg-spectum hover:bg-spectum-light text-white"
              } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-spectum-light uppercase`}
              onClick={() => handleNext(activeStep)}
              disabled={disabled}
            >
              {activeStep === 5
                ? i18next.t("cta.launch")
                : i18next.t("cta.next")}
            </button>
          </div>
        </div>
      )}
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("cta.launch")}
        message={i18next.t("order.question")}
        onClickSuccess={handleSubmit(onSubmit, onError)}
        onClickCancel={() => setOpen(false)}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderByRequest);
