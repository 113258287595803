import { CategoryResponse, CountryResponse } from "../../types";

export const actions = {
  CLEAR_SELECTION: "CLEAR_SELECTION",
  TOGGLE_COUNTRY: "TOGGLE_COUNTRY",
  TOGGLE_BUY_TEAM_CATEGORY: "TOGGLE_BUY_TEAM_CATEGORY",
  TOGGLE_SERVICE_FAMILY_CATEGORY: "TOGGLE_SERVICE_FAMILY_CATEGORY",
  TOGGLE_SERVICE_CLASS_CATEGORY: "TOGGLE_SERVICE_CLASS_CATEGORY",
  SELECT_ALL_SERVICE_CLASS_CATEGORIES: "SELECT_ALL_SERVICE_CLASS_CATEGORIES",
};

interface State {
  serviceFamilyCategories: CategoryResponse[];
  serviceClassCategories: CategoryResponse[];
  buyTeamCategories: CategoryResponse[];
  countries: CountryResponse[];
}

export const categoryFilterHandler = (
  categories: CategoryResponse[] | CountryResponse[],
  action: any
) => {
  const categorySet = new Set(categories.map((c) => c.id));
  if (categorySet.has(action.item.id)) {
    return categories.filter((category) => category.id !== action.item.id);
  }

  return [...categories, action.item];
};

export const categoryIdsBuilder = (data: any[]) => data.map((item) => item.id);

export const reducer = (state: State, action: any) => {
  let newCountries: CountryResponse[],
    newBuyTeamCategories: CategoryResponse[],
    newServiceFamilyCategories: CategoryResponse[],
    newServiceClassCategories: CategoryResponse[];

  switch (action.type) {
    case actions.TOGGLE_COUNTRY:
      newCountries = categoryFilterHandler(state.countries, action);
      return {
        ...state,
        countries: newCountries,
      };
    case actions.TOGGLE_BUY_TEAM_CATEGORY:
      newBuyTeamCategories = categoryFilterHandler(
        state.buyTeamCategories,
        action
      );
      if (state.buyTeamCategories.length !== newBuyTeamCategories.length) {
        const removedIds = state.buyTeamCategories
          .filter((cat) => !newBuyTeamCategories.includes(cat))
          .map((cat) => cat.id);
        newServiceFamilyCategories = state.serviceFamilyCategories.filter(
          (cat) => !removedIds.includes(cat.parent.id)
        );
        newServiceClassCategories = state.serviceClassCategories.filter((cat) =>
          newServiceFamilyCategories
            .map((fam) => fam.id)
            .includes(cat.parent.id)
        );
        return {
          ...state,
          buyTeamCategories: newBuyTeamCategories,
          serviceFamilyCategories: newServiceFamilyCategories,
          serviceClassCategories: newServiceClassCategories,
        };
      }
      return { ...state, buyTeamCategories: newBuyTeamCategories };

    case actions.TOGGLE_SERVICE_FAMILY_CATEGORY: {
      const updatedServiceFamilies = categoryFilterHandler(
        state.serviceFamilyCategories,
        action
      );

      // If categories changed, update dependent selections
      if (
        updatedServiceFamilies.length !== state.serviceFamilyCategories.length
      ) {
        const removedIds = new Set(
          state.serviceFamilyCategories.map((cat) => cat.id)
        );
        updatedServiceFamilies.forEach((cat) => removedIds.delete(cat.id));

        return {
          ...state,
          serviceFamilyCategories: updatedServiceFamilies,
          serviceClassCategories: state.serviceClassCategories.filter(
            (cat) => !removedIds.has(cat.parent.id)
          ),
        };
      }

      return { ...state, serviceFamilyCategories: updatedServiceFamilies };
    }

    case actions.TOGGLE_SERVICE_CLASS_CATEGORY:
      return {
        ...state,
        serviceClassCategories: categoryFilterHandler(
          state.serviceClassCategories,
          action
        ),
      };

    case actions.SELECT_ALL_SERVICE_CLASS_CATEGORIES:
      return {
        ...state,
        serviceClassCategories: action.items,
      };

    case actions.CLEAR_SELECTION:
      return {
        ...state,
        buyTeamCategories: [],
        serviceFamilyCategories: [],
        serviceClassCategories: [],
      };

    default:
      return state;
  }
};

export const extractBuyTeamCategories = (
  category: CategoryResponse
): CategoryResponse[] => {
  return category.parent
    ? [
        ...(category.type === "BUY_TEAM" ? [category] : []),
        ...extractBuyTeamCategories(category.parent),
      ]
    : category.type === "BUY_TEAM"
      ? [category]
      : [];
};

export const extractServiceFamilyCategories = (
  category: CategoryResponse
): CategoryResponse[] => {
  return category.parent
    ? [
        ...(category.type === "PRODUCT_FAMILY" ? [category] : []),
        ...extractServiceFamilyCategories(category.parent),
      ]
    : category.type === "PRODUCT_FAMILY"
      ? [category]
      : [];
};

export const extractServiceClassCategories = (
  category: CategoryResponse
): CategoryResponse[] => {
  return category.parent
    ? [
        ...(category.type === "PRODUCT_CLASS" ? [category] : []),
        ...extractServiceClassCategories(category.parent),
      ]
    : category.type === "PRODUCT_CLASS"
      ? [category]
      : [];
};
