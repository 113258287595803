import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ClipLoader } from "react-spinners";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import i18next from "../../i18n";
import InputField from "../UI/InputField/InputField";
import { useFileInputUploader } from "../../hooks";
import Button from "../UI/Button/Button";
import { DeliveryTime } from "../../types/deliveries.type";
import Modal from "../UI/Modal/Modal";
import Loader from "../UI/Loader/Loader";
import { SiteResponse, UserResponse } from "../../types";
import {
  deliveryValidationSchema,
  mapDispatchToProps,
  mapStateToProps,
  TO_BE_AGREED,
} from "../../utils";
import { useCreateDelivery } from "../../hooks/useDeliveries";
import { useSites } from "../../hooks/useSites";

type FormValues = {
  orderName: string;
  purchaseOrderNumber: string;
  email: string;
  confirmEmail: string;
  deliveryDate: string;
  siteId: string;
  deliveryTime: DeliveryTime;
  purchaseOrderFile: string;
  comment?: string;
};

const NewDeliveryForm = ({
  user,
  onOpenNotification,
}: {
  user: UserResponse;
  onOpenNotification: (message: string) => void;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deliveryPlaceId, setDeliveryPlaceId] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [fileError, setFileError] = useState<string | null>(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(deliveryValidationSchema),
  });

  const {
    uploadedSpecificationFileUrl,
    handleSpecificationUpload,
    isLoadingUpload,
  } = useFileInputUploader();

  const { data: sites, isLoading: isSitesLoading } = useSites(
    user?.company?.id
  );

  const { mutate: createDelivery, isPending: isCreatingDelivery } =
    useCreateDelivery(() => {
      onOpenNotification(i18next.t("deliveries.success"));
      navigate("/deliveries");
    });

  const handleSpecification = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]; // Get the first file
    if (file) {
      handleSpecificationUpload([file]);
      setFileName(file.name);
      setFileError("");
    }
  };

  const onSubmit = (data: FormValues) => {
    if (!uploadedSpecificationFileUrl) {
      setFileError(i18next.t("deliveries.form.validations.uploadError"));
      return;
    }
    setFileError("");
    createDelivery({
      ...data,
      purchaseOrderFile: uploadedSpecificationFileUrl,
      user: { id: user?.id },
      siteId: deliveryPlaceId,
      deliveryTime:
        data.deliveryTime === DeliveryTime.MORNING
          ? i18next.t("deliveries.form.morning")
          : data.deliveryTime === DeliveryTime.AFTERNOON
            ? i18next.t("deliveries.form.afternoon")
            : i18next.t("deliveries.form.night"),
    });
  };

  if (isCreatingDelivery || isSitesLoading) return <Loader isFullScreen />;

  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        icon={
          <CheckCircleIcon
            className="h-6 w-6 text-green-600"
            aria-hidden="true"
          />
        }
        title={i18next.t("deliveries.new")}
        message={i18next.t("deliveries.addQuestion")}
        onClickSuccess={handleSubmit(onSubmit)}
        onClickCancel={() => setOpen(false)}
      />
      <div className="px-4 mt-6">
        <h2 className="uppercase text-xl font-bold leading-7 text-gray-900 sm:truncate">
          {i18next.t("deliveries.new")}
        </h2>
        <form
          className="space-y-8 divide-y divide-gray-200 mt-8"
          autoComplete="off"
        >
          <div className="mt-10 sm:mt-0">
            <div className="mt-5 md:mt-0">
              <div className="grid grid-cols-6 gap-4 shadow border overflow-hidden sm:rounded p-4">
                <div className="col-span-6 md:col-span-3">
                  <InputField
                    id="orderName"
                    name="orderName"
                    type="text"
                    label={i18next.t("deliveries.form.orderName")}
                    errorName={errors?.orderName}
                    errorMessage={errors?.orderName?.message}
                    required
                    register={register}
                  />
                </div>
                <div className="col-span-6 md:col-span-3">
                  <InputField
                    id="purchaseOrderNumber"
                    name="purchaseOrderNumber"
                    type="text"
                    label={i18next.t("deliveries.form.purchaseOrderNumber")}
                    errorName={errors?.purchaseOrderNumber}
                    errorMessage={errors?.purchaseOrderNumber?.message}
                    required
                    register={register}
                  />
                </div>
                <div className="col-span-6 md:col-span-3">
                  <InputField
                    id="email"
                    name="email"
                    type="email"
                    label={i18next.t("deliveries.form.email")}
                    errorName={errors?.email}
                    errorMessage={errors?.email?.message}
                    required
                    register={register}
                  />
                </div>
                <div className="col-span-6 md:col-span-3">
                  <InputField
                    id="confirmEmail"
                    name="confirmEmail"
                    type="email"
                    label={i18next.t("deliveries.form.confirmEmail")}
                    errorName={errors?.confirmEmail}
                    errorMessage={errors?.confirmEmail?.message}
                    required
                    register={register}
                  />
                </div>

                <hr className="col-span-6" />

                <div className="col-span-6 md:col-span-2">
                  <InputField
                    id="deliveryDate"
                    name="deliveryDate"
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    label={i18next.t("deliveries.form.deliveryDate")}
                    errorName={errors?.deliveryDate}
                    errorMessage={errors?.deliveryDate?.message}
                    required
                    register={register}
                  />
                </div>
                <div className="col-span-6 md:col-span-2">
                  <label
                    htmlFor="deliveryTime"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("deliveries.form.deliveryTime")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <select
                    {...register("deliveryTime", { required: true })}
                    id="deliveryTime"
                    name="deliveryTime"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                  >
                    <option value="">
                      {i18next.t("deliveries.form.selectTimeRange")}
                    </option>
                    <option value={DeliveryTime.MORNING}>
                      {i18next.t("deliveries.form.morning")}
                    </option>
                    <option value={DeliveryTime.AFTERNOON}>
                      {i18next.t("deliveries.form.afternoon")}
                    </option>
                    <option value={DeliveryTime.NIGHT}>
                      {i18next.t("deliveries.form.night")}
                    </option>
                  </select>
                  {errors?.deliveryTime && (
                    <span className="text-xs text-red-500">
                      {errors?.deliveryTime?.message}
                    </span>
                  )}
                </div>

                <div className="col-span-6 md:col-span-2">
                  <label
                    htmlFor="siteId"
                    className="uppercase block text-xs font-medium text-gray-700"
                  >
                    {i18next.t("newMaterialForm.deliveryPlace")}
                    <span className="text-sm text-red-600"> *</span>
                  </label>
                  <select
                    {...register("siteId", { required: true })}
                    id="siteId"
                    name="siteId"
                    className="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-xs rounded"
                    defaultValue=""
                    onChange={(e) => setDeliveryPlaceId(e.target.value)}
                  >
                    <option value="">
                      {i18next.t("newMaterialForm.selectDeliveryPlace")}
                    </option>
                    {sites?.map((site: SiteResponse) => (
                      <option key={site.id} value={site.id}>
                        {site.name}
                      </option>
                    ))}
                    <option value={TO_BE_AGREED}>{TO_BE_AGREED}</option>
                  </select>

                  {errors?.siteId && !deliveryPlaceId && (
                    <span className="text-xs text-red-500">
                      {errors?.siteId?.message as any}
                    </span>
                  )}
                </div>

                <hr className="col-span-6" />

                <div className="col-span-6">
                  <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      {fileName && <span>{fileName}</span>}

                      <div className="text-xs text-gray-600">
                        <label
                          htmlFor="purchaseOrderFile"
                          className="uppercase relative cursor-pointer bg-white rounded font-medium text-spectum hover:text-spectum-light focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-spectum-light"
                        >
                          {isLoadingUpload ? (
                            <div className="flex w-full items-center justify-center">
                              <ClipLoader loading size={30} />
                            </div>
                          ) : (
                            <span>{i18next.t("deliveries.form.upload")}</span>
                          )}
                          <input
                            {...register("purchaseOrderFile")}
                            id="purchaseOrderFile"
                            name="purchaseOrderFile"
                            type="file"
                            className="sr-only"
                            onChange={(e) => handleSpecification(e)}
                          />
                          {errors?.purchaseOrderFile && (
                            <span className="text-xs text-red-500">
                              {errors?.purchaseOrderFile?.message}
                            </span>
                          )}
                        </label>
                      </div>

                      <p className="text-xs text-gray-500">PDF</p>
                    </div>
                  </div>
                  {fileError && (
                    <span className="text-xs text-red-500">{fileError}</span>
                  )}
                </div>
                <div className="col-span-6">
                  <InputField
                    id="comment"
                    name="comment"
                    type="text"
                    label={i18next.t("deliveries.form.comment")}
                    register={register}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex gap-2 justify-end">
            <Button link to="/deliveries" variant="cancel">
              {i18next.t("cta.cancel")}
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setOpen(true);
              }}
            >
              {i18next.t("cta.upload")}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NewDeliveryForm);
